import React from 'react'
import Seo from '../components/Seo'

export default function SeoSettings() {
  return <div>SeoTest</div>
}

export const Head = () => {
  return (
    <Seo
      title="Aurahomes"
      description="Ableaura Description"
      image="https://ik.imagekit.io/sbbplalyp/gp_muthu_Qa8L2sxEo.png"
      twitter_image="https://ik.imagekit.io/sbbplalyp/gp_muthu_Qa8L2sxEo.png"
    />
  )
}
