import * as React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata";

function Seo({ description, title, image, author, siteUrl }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl: defaultSiteUrl,
    author: defaultAuthor,
    imageUrl: defaultImageUrl,
  } = useSiteMetadata();

  const metaDescription = description || defaultDescription;
  const metaTitle = title || defaultTitle;
  const metaImage = image || defaultImageUrl;
  const metaAuthor = author || defaultAuthor;
  const metaUrl = siteUrl || defaultSiteUrl;

  return (
    <>
      <title>Cause Ads - Able Aura</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
      <link rel="canonical" href={metaUrl} />
      <meta http-equiv="language" content="en-US"></meta>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={metaAuthor} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </>
  );
}

Seo.defaultProps = {
  description: ``,
  image: ``,
};

Seo.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
